import axios from "axios";
import Cookies from "js-cookie";
import {message} from "antd";

const dev = false;

const TOKEN_KEY = "credential_token";
const PATH_PREFIX = "/back/domain";

export const ERR_NETWORK_FAIL = "-1";
export const ERR_SYSTEM = "100000";
export const ERR_RECORD_NOT_FOUND = "100001";
export const ERR_MISSING_ID = "100002";
export const ERR_AUTH_UNSATISFIED = "100003";
export const ERR_PARSE_TOKEN = "100004";
export const ERR_LIST_STATE = "110000";
export const ERR_REGISTERER_INVALID = "110001";
export const ERR_CHECKER_INVALID = "110002";
export const ERR_TYPE_PARSE = "110004";
export const RECORD_CANNOT_CANCEL = "140000";
export const INSUFFICIENT_DATA = "140003";
export const REPLENISH_EXCEED = "140004";
export const DRAIN_RUN_OUT = "140005";
export const INVALID_ADJUST = "140006";

export function infoError(err) {
    console.log("Err = ", err);
    switch (err.message) {
        case ERR_NETWORK_FAIL:
            message.error("网络错误").then(() => console.log("message showed finished"));
            break;
        case ERR_SYSTEM:
            message.error("系统错误，请检查控制台").then(() => console.log("message showed finished"));
            break;
        case ERR_RECORD_NOT_FOUND:
            message.error("未找到记录").then(() => console.log("message showed finished"));
            break;
        case ERR_MISSING_ID:
            message.error("ID无效").then(() => console.log("message showed finished"));
            break;
        case ERR_AUTH_UNSATISFIED:
            message.error("权限不足").then(() => console.log("message showed finished"));
            break;
        case ERR_PARSE_TOKEN:
            message.error("未登录，请先登陆").then(() => console.log("message showed finished"));
            break;
        case ERR_LIST_STATE:
            message.error("单据状态异常").then(() => console.log("message showed finished"));
            break;
        case ERR_REGISTERER_INVALID:
            message.error("单据登记人需保持一致").then(() => console.log("message showed finished"));
            break;
        case ERR_CHECKER_INVALID:
            message.error("审核人与登记人不能为同一人").then(() => console.log("message showed finished"));
            break;
        case ERR_TYPE_PARSE:
            message.error("类型解析失败").then(() => console.log("message showed finished"));
            break;
        case RECORD_CANNOT_CANCEL:
            message.error("单据无法取消审核").then(() => console.log("message showed finished"));
            break;
        case "130001":
            message.error("该条目已被审核").then(() => console.log("message showed finished"));
            break;
        case "150001":
            message.error("检测到单据依赖，无法取消").then(() => console.log("message showed finished"));
            break;
        case INSUFFICIENT_DATA:
            message.error("数据不充分").then(() => console.log("message showed finished"));
            break;
        case REPLENISH_EXCEED:
            message.error("数据超出上限").then(() => console.log("message showed finished"));
            break;
        case DRAIN_RUN_OUT:
            message.error("库存不足").then(() => console.log("message showed finished"));
            break;
        case INVALID_ADJUST:
            message.error("无效调整").then(() => console.log("message showed finished"));
            break;
        default:
            message.error("网络错误").then(() => console.log("message showed finished"));
    }
}

export function logout() {
    Cookies.remove(TOKEN_KEY);
    sessionStorage.removeItem(TOKEN_KEY);
}

function getToken() {
    const token = sessionStorage.getItem(TOKEN_KEY);
    if (typeof token === "string") {
        return token;
    }
    const cookie = Cookies.get(TOKEN_KEY);
    if (typeof cookie === "undefined") {
        return undefined;
    }
    sessionStorage.setItem(TOKEN_KEY, cookie);
    return cookie;
}

export async function requests(path, data) {
    let token;
    if (!dev) {
        token = getToken();
        if (typeof token === "undefined") {
            throw new Error(ERR_PARSE_TOKEN);
        }
    } else {
        token = "MAPLES_DEV";
    }
    let response = null;
    try {
        // noinspection JSCheckFunctionSignatures
        response = await axios.request({
            method: "post",
            url: `${PATH_PREFIX}/${path}`,
            data: data ?? {},
            headers: {
                Authorization: token
            },
        });
    } catch (e) {
        console.log("ERR_NETWORK_FAIL", e);
        throw new Error(ERR_NETWORK_FAIL);
    }
    console.log("Request Received:", response.data);
    if (response.data["Code"] !== 0) {
        console.log("Request Fail:", response.data["SerialNum"], response.data["Message"]);
        throw new Error(response.data["Code"].toString());
    }
    return response.data["Data"];
}

export async function upload(path, file, dir, onProgress) {
    const token = getToken();
    if (typeof token === "undefined") {
        throw new Error(ERR_PARSE_TOKEN);
    }
    let response = null;
    try {
        const data = new FormData();
        data.append("file", file);
        if (typeof dir !== "undefined") {
            data.append("path", dir);
        }
        // noinspection JSCheckFunctionSignatures
        response = await axios.post(`${PATH_PREFIX}/${path}`, data, {
            headers: {
                Authorization: token,
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: ({total, loaded}) => {
                if (typeof onProgress === "function") {
                    onProgress({percent: Math.round((loaded / total) * 100).toFixed(2)}, file);
                }
            },
        });
    } catch (e) {
        console.log("ERR_NETWORK_FAIL", e);
        throw new Error(ERR_NETWORK_FAIL);
    }
    console.log("Upload Received:", response.data);
    if (response.data["Code"] !== 0) {
        console.log("Upload Fail:", response.data["SerialNum"], response.data["Message"]);
        throw new Error(response.data["Code"].toString());
    }
    return response.data["Data"];
}

export async function take(path, id) {
    return await requests(path, {Payload: id});
}

export async function fetchIDProps(ctx) {
    if (typeof ctx.query["id"] !== "string" || ctx.query["id"] === "") {
        return {props: {id: ""}};
    }
    return {props: {id: ctx.query["id"]}};
}

export async function fetchItemInfos(ids) {
    const set = new Set();
    for (const id of ids) {
        // [BugFix]: id might be number because of js
        set.add(id.toString());
    }
    return await requests("/inventory/item/fetch", {
        Payload: [...set],
    });
}

export async function fetchItemInfo(id) {
    const response = await fetchItemInfos([id]);
    if (response.length !== 1) {
        throw new Error(ERR_RECORD_NOT_FOUND);
    }
    return response[0];
}

export function fetchInitialList(url, id, setRecord) {
    if (id === "") {
        return;
    }
    requests(url, {Payload: id}).then(r => setRecord(r)).catch(e => infoError(e));
}

export async function fetchInformation(path, keyword) {
    try {
        return await requests(`/information${path}`, {Payload: keyword});
    } catch (e) {
        return [];
    }
}