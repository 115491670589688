import React from "react";
import {requires} from "utils/auth";
import {FormOutlined, ShopOutlined} from "@ant-design/icons";

/**
 * Control the entrance of sidebar
 * @param {string[]} auths
 * @return {object}
 */
export function entrances(auths) {
    const models = {
        system: {icon: <FormOutlined/>, name: "系统管理", items: []},
        finance: {icon: <ShopOutlined/>, name: "财务管理", items: []},
        inventory: {icon: <ShopOutlined/>, name: "库存管理", items: []},
        purchase: {icon: <ShopOutlined/>, name: "采购管理", items: []},
        sales: {icon: <ShopOutlined/>, name: "销售管理", items: []},
    };
    if (requires(auths, ["system:query"])) {
        models.system.items.push({key: "/system/users", label: "用户管理"});
        models.system.items.push({key: "/system/accounts", label: "会计科目"});
    }
    if (requires(auths, ["finance:query"])) {
        models.finance.items.push({key: "/finance", label: "财务管理"});
        // models.finance.items.push({key: "/finance/query", label: "财务查询"});
        // models.finance.items.push({key: "/finance/voucher", label: "会计凭据"});
        // models.finance.items.push({key: "/finance/reconciliation", label: "财务对账"});
    }
    if (requires(auths, ["inventory:query"])) {
        // models.inventory.items.push({key: "/inventory", label: "库存总览"});
        models.inventory.items.push({key: "/inventory/consignment", label: "代销单管理"});
        if (requires(auths, ["inventory:query", "inventory:edit"])) {
            models.inventory.items.push({key: "/inventory/warehousing", label: "入库单管理"});
            models.inventory.items.push({key: "/inventory/adjusting", label: "调整单管理"});
            models.inventory.items.push({key: "/inventory/checking", label: "盘点单管理"});
        }
    }
    if (requires(auths, ["sales:query"])) {
        models.sales.items.push({key: "/sales/information", label: "信息查询"});
        models.sales.items.push({key: "/sales/list", label: "销售单管理"});
        models.sales.items.push({key: "/sales/delivery", label: "留石单管理"});
        models.sales.items.push({key: "/sales/payment", label: "付款单管理"});
    }
    if (requires(auths, ["purchase:query"])) {
        // models.purchase.items.push({key: "/purchase/records", label: "采购记录"});
        models.purchase.items.push({key: "/purchase/list", label: "采购单管理"});
    }
    const result = {};
    for (const [k, v] of Object.entries(models)) {
        if (v.items.length > 0) {
            result[k] = v;
        }
    }
    return result;
}