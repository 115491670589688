import React from "react";
import "antd/dist/reset.css";
import "dayjs/locale/zh-cn";
import Head from "next/head";
import zhCN from "antd/locale/zh_CN";
import {Col, ConfigProvider, Layout, Row} from "antd";
import {AuthContext} from "components/context/auth-context";
import {requests} from "utils/requests";
import {PageSider} from "components/common/page-sider";
import {UserProfile} from "components/common/user-profile";

// [Bug]: It has to be pageProps, otherwise page cannot get props derived by getServerSideProps
export default function Index({Component, pageProps}) {
    const [credential, setCredential] = React.useState({
        "ID": "",
        "Subject": "",
        "Audience": [],
    });
    React.useEffect(() => {
        // noinspection JSCheckFunctionSignatures
        requests("/credential").then(c => setCredential(c)).catch(e => console.log(e));
    }, []);
    return (
        <AuthContext.Provider value={credential}>
            <Head>
                <title>GRANDET系统</title>
            </Head>
            <ConfigProvider
                locale={zhCN}
                theme={{
                    token: {
                        // colorPrimary: "#08979c",
                        borderRadius: 3,
                    },
                    components: {
                        Button: {
                            // colorPrimary: "#003eb3",
                            algorithm: true,
                        },
                    },
                }}
            >
                <Layout>
                    <Layout.Header style={{paddingLeft: 3, background: "#001d66", boxShadow: ""}}>
                        <Row>
                            <Col span={12}>
                                {/*<Image*/}
                                {/*    alt=""*/}
                                {/*    style={{marginLeft: 30}}*/}
                                {/*    src={"/GRANDET-LOGO.svg"}*/}
                                {/*    preview={false}*/}
                                {/*    width={100}*/}
                                {/*    height={75}*/}
                                {/*/>*/}
                            </Col>
                            <Col span={12}>
                                <Row justify="end">
                                    <Col>
                                        <UserProfile/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Layout.Header>
                    <Layout hasSider={true}>
                        <PageSider/>
                        <Layout style={{
                            paddingTop: 24,
                            paddingRight: 24,
                            paddingLeft: 24,
                            // paddingBottom: 27,
                        }}>
                            <Layout.Content style={{
                                padding: 24,
                                minHeight: "81vh",
                                background: "#fff",
                            }}>
                                <Component {...pageProps} />
                            </Layout.Content>
                            <Layout.Footer style={{textAlign: "center"}}>
                                备案号: <a href={"https://beian.miit.gov.cn/"}>粤ICP备2021175635号-1</a>
                                <p style={{marginTop: 10}}>
                                    Copyright © 2022 Maples. All rights reserved.
                                </p>
                            </Layout.Footer>
                        </Layout>
                    </Layout>
                </Layout>
            </ConfigProvider>
        </AuthContext.Provider>
    );
}
