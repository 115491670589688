import React from "react";
import Link from "next/link";
import {Avatar, Dropdown} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {useRouter} from "next/router";
import {AuthContext} from "components/context/auth-context";
import {logout} from "utils/requests";

export const UserProfile = () => {
    const router = useRouter();
    // noinspection JSCheckFunctionSignatures
    const credential = React.useContext(AuthContext);
    const openUrl = "https://open.work.weixin.qq.com/wwopen/sso/qrConnect?";
    const params = [
        "appid=ww08281920cd448f0c",
        "agentid=1000003",
        "redirect_uri=https://acernus.cn/api/login",
        "state=STATE"
    ];
    if (credential.ID === "") {
        const items = [
            {key: 0, label: (<Link href={openUrl + params.join("&")}>登陆</Link>)},
        ];
        // noinspection JSUnresolvedReference
        if (typeof process.env["NEXT_PUBLIC_DEBUG"] !== "undefined" && process.env["NEXT_PUBLIC_DEBUG"] === "1") {
            items.push({key: 1, label: (<Link href="/api/login?code=developer@ZGV2I3Bhc3Mx">开发者登陆</Link>)});
            items.push({key: 2, label: (<Link href="/api/login?code=xiaolinzhen@MTIzNDU2">销售登陆</Link>)});
            items.push({key: 3, label: (<Link href="/api/login?code=liujianli@MTIzNDU2">经理登陆</Link>)});
            items.push({key: 4, label: (<Link href="/api/login?code=litianlin@MTIzNDU2">管理员登陆</Link>)});
        }
        // noinspection JSValidateTypes
        return (
            <Dropdown arrow placement="bottomRight" menu={{items: items}}>
                <Avatar
                    size={40} shape="square" style={{color: "#f56a00", backgroundColor: "#fde3cf"}}
                    icon={<UserOutlined/>}
                />
            </Dropdown>
        );
    }
    // noinspection JSValidateTypes
    return (
        <Dropdown
            arrow
            placement="bottomRight"
            menu={{
                items: [
                    {
                        key: 1,
                        label: (
                            <Link onClick={() => {
                                logout();
                                router.reload();
                            }} href="#">注销</Link>
                        )
                    }
                ]
            }}
        >
            <Avatar size={40} shape="square" style={{color: "#f56a00", backgroundColor: "#fde3cf"}}>
                {credential.Subject}
            </Avatar>
        </Dropdown>
    );
};
