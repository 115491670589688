import {Layout, Menu} from "antd";
import Link from "next/link";
import React from "react";
import {AuthContext} from "components/context/auth-context";
import {useRouter} from "next/router";
import {entrances} from "utils/entrance";

export const PageSider = () => {
    // noinspection JSCheckFunctionSignatures
    const credential = React.useContext(AuthContext);
    const router = useRouter();
    const menuItems = React.useMemo(() => {
        const result = [
            {key: "/", label: <Link href="/" style={{textDecoration: "none"}}>主页</Link>},
        ];
        const items = entrances(credential.Audience);
        for (const [name, entries] of Object.entries(items)) {
            result.push(
                {
                    key: name,
                    icon: entries.icon,
                    label: entries.name,
                    children: entries.items.map((elem) => ({
                        key: elem.key,
                        label: <Link href={elem.key} style={{textDecoration: "none"}}>{elem.label}</Link>,
                    })),
                },
            );
        }
        return result;
    }, [credential.Audience]);
    let key = router.pathname;
    if (key.endsWith("/edit")) {
        key = key.replace("/edit", "");
    }
    console.log("PageSider Opened Key =", key);
    // noinspection JSValidateTypes
    return (
        <Layout.Sider theme="dark" style={{paddingTop: "13px", background: "#fff"}}>
            <Menu
                // theme="dark"
                mode="inline"
                items={menuItems}
                defaultOpenKeys={[router.pathname.split("/")[1]]}
                defaultSelectedKeys={[key]}
            />
        </Layout.Sider>
    );
};