export function requires(audience, auths) {
    for (const auth of auths) {
        let satisfied = false;
        for (const authPattern of audience) {
            const pattern = new RegExp(authPattern);
            if (pattern.test(auth)) {
                satisfied = true;
                break;
            }
        }
        if (!satisfied) {
            return false;
        }
    }
    return true;
}